@import 'src/styles/partials';

.default {
  @apply h4
  pt-[15px]
  pb-[14px]
  flex
  items-center
  justify-center
  w-full
  bg-blue
  rounded-full
  text-white
  text-center
  focus:outline-none
  focus:ring-2
  focus:ring-blue;

  //box-shadow: 0 5px 15px rgba(13, 102, 252, 0.25);
  transition: all 500ms $ease-out-expo;

  &.inverted {
    @apply text-blue
    border
    border-blue
    bg-transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }

  &.warning {
    @apply text-red
    border
    border-red
    bg-transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: 0 5px 15px rgba(255, 126, 113, 0.3);
    }
  }

  &.secondary {
    @extend .caption;

    @apply bg-transparent
    text-blue;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }

  &:hover,
  &:focus {
    box-shadow: 0 5px 15px rgba(13, 102, 252, 0.7);
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      cursor: initial;
      box-shadow: none;
    }
  }
}
