.dialog {
  @apply fixed
  z-50
  inset-0
  overflow-y-auto
  sm:grid
  sm:grid-cols-12
  sm:justify-center;
}

.boxContainer {
  @apply flex
  items-end
  min-h-screen
  text-center
  flex-col
  justify-end
  sm:flex-row
  sm:items-center
  sm:justify-center
  sm:col-start-4
  sm:col-span-6;
}

.backdrop {
  @apply fixed
  inset-0
  bg-black
  bg-opacity-80
  transition-opacity;
}

.box {
  @apply inline-block
  align-bottom
  bg-light-blue
  text-left
  transform
  transition-all
  relative
  rounded-tr-[30px]
  rounded-tl-[30px]
  
  px-3
  py-8
  
  w-full
  max-w-screen-sm
  mt-6
  sm:rounded-[60px]
  sm:mt-[100px];
}

.boxInner {
  @apply flex flex-col justify-center;
  min-height: 300px;
  max-width: 600px;
  margin: 0 auto;

  @screen sm {
    margin: auto;
  }
}

.closeButton {
  @apply flex
  items-center
  justify-center
  bg-yellow
  rounded-full
  z-50
  focus:outline-none
  focus:ring-2
  focus:ring-blue
  w-[60px]
  h-[60px];
  margin: 0 auto;

  @screen sm {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
  }
}
