@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts';
@import 'typography';
@import 'utils';
@import 'notiflix';

html,
* {
  color: #191413;
}

html {
  font-size: 16px;
  font-family: 'Campton', sans-serif;
}
