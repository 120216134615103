.rowHeader {
  @apply hidden
  md:grid
  pb-6
  grid-cols-2
  md:grid-cols-8
  items-center;
  @screen md {
    grid-template-columns: 2fr 2fr 2fr 2fr;
  }
  @screen 2xl {
    grid-template-columns: 2fr 2fr 3fr 1fr;
  }
}

.row {
  @apply grid
  pb-3
  md:pb-7
  mb-10
  md:mb-7
  border-b
  grid-cols-2
  md:grid-cols-8
  items-start
  md:items-center;

  &:nth-child(even) {
    @apply order-1;
  }
  @screen md {
    grid-template-columns: 2fr 2fr 2fr 2fr;
  }
  @screen 2xl {
    grid-template-columns: 2fr 2fr 3fr 1fr;
  }
}
