@import 'src/styles/partials';

.wrapper {
  @apply w-full relative;
}

.select_label {
  @apply h4 block text-black mb-2;
  height: 16px;
  &.error {
    @apply text-red;
  }
}

.button {
  @apply h4
  px-4
  pt-[15px]
  pb-[14px]
  w-full
  block
  text-left
  bg-light-blue
  focus:outline-none
  focus:ring-2
  focus:ring-blue
  lg:focus-within:ring-offset-blue
  border-none
  rounded-[10px];

  &.white {
    @apply bg-white;
  }

  &.error {
    @apply ring-2 ring-red;
  }
}

.option {
  @apply h4
  px-2
  py-5
  w-full
  block
  text-red
  bg-light-blue
  focus:outline-none
  focus:ring-2
  focus:ring-blue
  lg:focus-within:ring-offset-blue
  border-none
  rounded-md;
}

.options_box {
  @apply absolute
  h-32
  z-10
  mt-1
  w-full
  bg-white
  shadow-lg
  max-h-60
  rounded-2xl
  py-1
  text-base
  ring-1
  ring-black
  ring-opacity-5
  overflow-auto
  focus:outline-none
  sm:text-sm;
}
