#NotiflixNotifyWrap {
  display: flex;
  justify-content: center;
  margin-top: 5.2rem !important;
  @screen md {
    margin-top: 7rem !important;
  }
}

.notiflix-notify-success,
.notiflix-notify-failure,
.notiflix-notify-warning,
.notiflix-notify-info {
  display: inline-block;
  background-repeat: no-repeat !important;
  background-position: 18px center !important;
  background-size: 33px !important;
  padding: 25.6px 25.6px 25.6px 66px !important;
  font-weight: 500 !important;
  width: auto !important;
  * {
    font-weight: 500 !important;
  }
}

.notiflix-notify-success {
  background-image: url('/img/circleCheck.png') !important;
}

.notiflix-notify-failure {
  padding: 25.6px 25.6px 25.6px 25.6px !important;
}
