.default {
  @apply h-screen
  w-full
  flex
  flex-row
  bg-light-blue
  justify-center
  items-center
  px-4;
  //padding-top: 85px;
  margin-bottom: -85px;
}
