@import 'src/styles/partials';

.default {
  @apply relative
  w-10
  h-6
  border-2
  inline-flex
  flex-shrink-0
  rounded-full
  border-transparent
  cursor-pointer
  bg-gray-200
  focus:outline-none;

  &.checked {
    @apply bg-blue shadow-blue-25;
  }
}

.ball {
  @apply h-5
  w-5
  pointer-events-none
  inline-block
  rounded-full
  bg-white
  transform
  ring-0
  translate-x-0;

  &.checked {
    @apply translate-x-4;
  }
}

.switch_label {
  @extend .caption;
  @apply ml-3 font-bold;
}
