.list {
  list-style: circle;
  margin-left: 20px;
}

.clickable {
  @apply hover:cursor-pointer;
}

.desktop-only {
  @apply hidden lg:block;
}

.mobile-only {
  @apply block lg:hidden;
}

.rich-text {
  p {
    @apply mb-3;
  }
}

.full-height-page {
  @apply h-screen w-full flex flex-col justify-center items-center;
}

.light-blue {
  @apply bg-blue;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.lg\:grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
