.current {
  @apply border-black/5 border;
  background: radial-gradient(
      59.36% 60.49% at 50% 50%,
      #f4cfff 0%,
      rgba(248, 207, 255, 0) 100%
    ),
    linear-gradient(0deg, #fafafa 0%, #fafafa 100%), #eef4ff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
}
