.link {
  @apply text-blue hover:cursor-pointer;
}

.d1 {
  font-size: 5rem; /* 80px */
  font-weight: 500;
  font-style: normal;
  line-height: 95%;
  letter-spacing: -0.04em;
  @screen md {
    font-size: 7.81rem; /* 125px */
  }
}

.d2 {
  font-size: 2.85rem; /* 60px */
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  letter-spacing: -0.04em;
  @screen md {
    font-size: 5rem; /* 80px */
  }
}

.copy {
  font-size: 1.31rem; /* 21px */
  font-weight: 300;
  font-style: normal;
  line-height: 130%;
  letter-spacing: -0.02em;
  @screen md {
    font-size: 1.75rem; /* 28px */
  }
}

.copy-2 {
  font-size: 0.94rem; /* 15px */
  font-weight: 300;
  font-style: normal;
  line-height: 148%;
  letter-spacing: -0.01em;
  @screen md {
    font-size: 1rem; /* 16px */
  }
}

.caption {
  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
  letter-spacing: -0.01em;
}

.disclaimer {
  font-size: 0.56rem; /* 9px */
  font-weight: 400;
  font-style: normal;
  line-height: 125%;
  letter-spacing: -0.01em;
  @screen md {
    font-size: 0.63rem; /* 10px */
  }
}

.label {
  font-size: 0.69rem; /* 11px */
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @screen md {
    font-size: 0.75rem; /* 12px */
  }
}

.label-2 {
  font-size: 0.56rem; /* 9px */
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @screen md {
    font-size: 0.63rem; /* 10px */
  }
}

.text-stroke {
  @supports (-webkit-text-stroke: 1px black) {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: transparent;
  }
}
