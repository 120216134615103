@import 'src/styles/partials';

.icon {
  @apply absolute top-4 right-4 w-4 flex items-center;
}

.wrapper {
  @apply w-full relative mt-2;
}

.input_label {
  @apply h4 block text-black;
  height: 16px;

  &.disabled {
    @apply opacity-50;
  }

  &.error {
    @apply text-red;
  }
}

.input_outer {
  @apply w-full relative flex items-center;
}

.input {
  @apply h4
  relative
  px-4
  pt-[15px]
  pb-[14px]
  mb-2
  w-full
  block
  text-blue
  placeholder-blue
  placeholder-opacity-25
  bg-light-blue
  rounded-[10px]
  focus:outline-none
  focus:ring-2
  border-none
  lg:focus-within:ring-offset-blue-30;

  &.disabled {
    @apply opacity-50;
  }

  &.white {
    @apply bg-white;
  }
  &.rounded {
    @apply rounded-full w-full;
  }

  &.error {
    @apply ring-2 ring-red;
  }
}
