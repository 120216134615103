/**  * @license
 * MyFonts Webfont Build ID 3837612, 2019-11-15T17:02:54-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Campton-Book by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/book/
 * Copyright: Copyright &#x00A9; 2017 by Ren&#x00E9; Bieder. All rights reserved.
 *
 * Webfont: Campton-Light by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/light/
 * Copyright: Copyright &#x00A9; 2017 by Ren&#x00E9; Bieder. All rights reserved.
 *
 * Webfont: Campton-Medium by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/campton/medium/
 * Copyright: Copyright &#x00A9; 2017 by Ren&#x00E9; Bieder. All rights reserved.
 *
 * Licensed pageviews: 100,000
 *
 * © 2019 MyFonts Inc **/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3a8eac');

@layer base {
  // light
  @font-face {
    font-family: Campton;
    src: url('/fonts/3A8EAC_B_0.woff') format('woff'),
      url('/fonts/3A8EAC_B_0.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: block;
    -webkit-font-smoothing: antialiased;
  }

  // Book
  @font-face {
    font-family: Campton;
    src: url('/fonts/3A8EAC_5_0.woff') format('woff'),
      url('/fonts/3A8EAC_5_0.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
    -webkit-font-smoothing: antialiased;
  }

  // Medium
  @font-face {
    font-family: Campton;
    src: url('/fonts/3A8EAC_D_0.woff') format('woff'),
      url('/fonts/3A8EAC_D_0.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
    -webkit-font-smoothing: antialiased;
  }
}
