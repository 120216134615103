.container {
  @apply flex
    items-center
    relative
    overflow-hidden
    lg:w-screen;

  @screen lg {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: -80px;
  }
}

.grid {
  @apply grid-cols-12
    max-w-screen-sm
    lg:max-w-screen-lg;
  &.centered {
    @apply items-center;
  }
}

.childrenContainer {
  @apply w-full
    lg:m-0;
}

.circleWrapper {
  @apply absolute
    rounded-full
    overflow-hidden;
  z-index: -1;
  top: -3rem;
  right: 50%;
  width: 150vh;
  height: 150vh;
  transform: translate(50%, 0);

  @screen xs {
    width: 300vw;
    height: 300vw;
    transform: translate(50%, 0);
  }

  &.removeMobile {
    .circle {
      display: none;
      @screen lg {
        display: block;
      }
    }
  }

  @screen lg {
    width: 200vh;
    height: 200vh;
    bottom: auto;
    top: 50%;
    right: -8rem;
    transform: translateY(-50%);
    &.large {
      right: -6rem;
    }
  }
  &.invert {
    top: auto;
    bottom: -2rem;
    @screen lg {
      top: 50%;
      bottom: auto;
    }
  }
}

.circle {
  @apply absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  max-width: none;
  transform: translate(-15%, -72%);

  .large & {
    height: 45%;
    transform: translate(-62%, -135%);
  }

  @screen xs {
    top: 50%;
  }

  @screen md {
    .large & {
      height: 35%;
      transform: translate(-78%, -180%);
    }
  }
  @screen lg {
    right: 0;
    width: 86%;
    height: auto;
    transform: translate(5%, -50%);
    .large & {
      width: 75%;
      height: auto;
      transform: translateY(-50%);
    }
  }
  .invert & {
    height: 200%;
    top: 0;
    transform: translate(10%, -10%);
    @screen xs {
      top: 50%;
      height: 140%;
      transform: translate(0, -50vh);
    }
    @screen lg {
      height: auto;
      transform: translate(5%, -50%);
    }
  }
}
