@import 'src/styles/partials';

.title {
  * {
    @apply text-blue;
  }
  s {
    opacity: 0.3;
    text-decoration: line-through;
    * {
      text-decoration: line-through;
    }
  }
  small {
    font-size: 1rem;
    display: inline-block;
    font-weight: 400;
    margin-left: -0.3em;
    transform: translateY(-0.5em);
  }
}

.promoFormat {
  small {
    @apply text-white;
    font-size: 0.6em;
    display: inline-block;
    font-weight: 300;
    margin-left: 0.2em;
    transform: translateY(-0.4em);
  }
}

.item_discount {
  @apply flex
  flex-col
  -bottom-px
  -left-px
  pt-2
  pb-1
  absolute
  bg-blue;
  border-top-right-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;

  &.bannerCrossed {
    &::after {
      content: '';
      top: 40%;
      width: 70%;
      height: 2px;
      position: absolute;
      background: white;
    }
  }
}

.item_notification {
  @apply flex
  z-10
  items-center
  -top-2
  left-1
  px-2
  absolute
  bg-pink
  rounded-full;

  padding-top: 0.25rem;
  height: 1.75rem;
}

.item_wrapper {
  @apply w-full
  h-full
  flex
  flex-col
  justify-start
  relative
  select-none
  py-5
  lg:px-3;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;

  h3 {
    font-size: 5vw;
    line-height: 28px;
    padding-right: 4rem;
    @screen xs {
      font-size: 24px;
      padding-right: 0;
    }
  }
}

.discounted {
  padding-bottom: 3rem;
}

.item_container {
  @apply overflow-hidden
  relative
  w-full
  h-full
  p-px
  mb-3
  md:mb-4;
  border: 1px solid #c4c4c4;
  border-radius: 20px;

  &.isDisabled {
    @apply bg-light-grey border-light-grey;
  }

  &.clickable {
    @apply hover:cursor-pointer;

    &.checked {
      box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
    }
  }

  &.checked {
    @apply bg-light-blue border-blue border-2 p-0;
  }

  &.current {
    @apply border-black/5 border;
    background: radial-gradient(
        59.36% 60.49% at 50% 50%,
        #f4cfff 0%,
        rgba(248, 207, 255, 0) 100%
      ),
      linear-gradient(0deg, #fafafa 0%, #fafafa 100%), #eef4ff;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  }
}

.checkbox {
  position: absolute;
  top: 18px;
  right: 18px;
  display: flex;
  svg {
    width: 22px;
    height: 22px;
  }
}
.checkboxEmpty {
  position: absolute;
  top: 18px;
  right: 18px;
  display: flex;
  border-radius: 22px;
  width: 22px;
  height: 22px;
  border: 1px solid #c4c4c4;
}
